export const ROUGH_STOCK_TYPE = 'ROUGH_STOCK_TYPE';
export const TIMED_DISCIPLINE_TYPE = 'TIMED_DISCIPLINE_TYPE';
export const BARREL_RACING_TYPE = 'BARREL_RACING_TYPE';

const BAREBACK = 8;
const SADDLE_BRONC = 9;
const TEAM_ROPING_HEADER = 10;
const TEAM_ROPING_HEELER = 11;
const BARREL_RACING = 13;
const TIE_DOWN = 12;
const BULL_RIDING = 14;
const STEER_WRESTLING = 15;
const LADIES_BREAKAWAY_ROPING = 149;
const STEER_ROPING = 165;
const JUNIOR_BARREL_RACE = 166;
const JUNIOR_STEER_RIDE = 167;
const JUNIOR_BULL_RIDING = 168;
const JUNIOR_BREAKAWAY = 169;
const JUNIOR_TEAM_ROPING = 170;
const OTHER = 171;
const LADIES_STEER_UNDECORATING = 172;
const SIDE_POT = 173;
const RANCH_BRONC_RIDING = 174;
const OVER_55S_TEAM_ROPING = 175;
const LADIES_POLE_BENDING = 182;
const GOAT_TYING = 183;
const TIEDOWN_CALF_ROPING = 184;
const DALLY_RIBBON_ROPER = 185;
const DALLY_RIBBON_RUNNER = 203;
const GIRLS_BREAKAWAY_ROPING = 186;
const LADIES_GOAT_TYING = 187;
const BOYS_BREAKAWAY_ROPING = 188;
const BOYS_GOAT_TYING = 189;
const CHUTE_DOGGING = 190;
const SADDLE_BRONC_ON_STEERS = 191;
const BAREBACK_ON_STEERS = 192;
const RIBBON_ROPING = 193;

export const disciplinesById = {
  BAREBACK,
  SADDLE_BRONC,
  TEAM_ROPING_HEADER,
  TEAM_ROPING_HEELER,
  BARREL_RACING,
  TIE_DOWN,
  BULL_RIDING,
  STEER_WRESTLING,
  LADIES_BREAKAWAY_ROPING,
  STEER_ROPING,
  JUNIOR_BARREL_RACE,
  JUNIOR_STEER_RIDE,
  JUNIOR_BULL_RIDING,
  JUNIOR_BREAKAWAY,
  JUNIOR_TEAM_ROPING,
  OTHER,
  LADIES_STEER_UNDECORATING,
  SIDE_POT,
  RANCH_BRONC_RIDING,
  OVER_55S_TEAM_ROPING,
  LADIES_POLE_BENDING,
  GOAT_TYING,
  TIEDOWN_CALF_ROPING,
  DALLY_RIBBON_ROPER,
  DALLY_RIBBON_RUNNER,
  GIRLS_BREAKAWAY_ROPING,
  LADIES_GOAT_TYING,
  BOYS_BREAKAWAY_ROPING,
  BOYS_GOAT_TYING,
  CHUTE_DOGGING,
  SADDLE_BRONC_ON_STEERS,
  BAREBACK_ON_STEERS,
  RIBBON_ROPING,
};

export const disciplinesByName = {
  [BAREBACK]: 'Bareback',
  [SADDLE_BRONC]: 'Saddle Bronc',
  [STEER_WRESTLING]: 'Steer Wrestling',
  [TEAM_ROPING_HEADER]: 'Team Roping Header',
  [TEAM_ROPING_HEELER]: 'Team Roping Heeler',
  [BARREL_RACING]: 'Barrel Racing',
  [TIE_DOWN]: 'Tie Down',
  [BULL_RIDING]: 'Bull Riding',
  [LADIES_BREAKAWAY_ROPING]: 'Ladies Breakaway Roping',
  [STEER_ROPING]: 'Steer Roping',
  [JUNIOR_BARREL_RACE]: 'Junior Barrel Race',
  [JUNIOR_STEER_RIDE]: 'Junior Steer Ride',
  [JUNIOR_BULL_RIDING]: 'Jr. Bull Riding',
  [JUNIOR_BREAKAWAY]: 'Junior Breakaway',
  [JUNIOR_TEAM_ROPING]: 'Junior Team Roping',
  [OTHER]: 'Other',
  [LADIES_STEER_UNDECORATING]: 'Ladies Steer Undecorating',
  [SIDE_POT]: 'Side Pot',
  [RANCH_BRONC_RIDING]: 'Ranch Bronc Riding',
  [OVER_55S_TEAM_ROPING]: 'Over 55s Team Roping',
  [LADIES_POLE_BENDING]: 'Ladies Pole Bending',
  [GOAT_TYING]: 'Goat Tying',
  [TIEDOWN_CALF_ROPING]: 'Tiedown Calf Roping',
  [DALLY_RIBBON_ROPER]: 'Dally Ribbon Roper',
  [DALLY_RIBBON_RUNNER]: 'Dally Ribbon Runner',
  [GIRLS_BREAKAWAY_ROPING]: 'Girls Breakaway Roping',
  [LADIES_GOAT_TYING]: 'Ladies Goat Tying',
  [BOYS_BREAKAWAY_ROPING]: 'Boys Breakaway Roping',
  [BOYS_GOAT_TYING]: 'Boys Goat Tying',
  [CHUTE_DOGGING]: 'Chute Dogging',
  [SADDLE_BRONC_ON_STEERS]: 'Saddle Bronc on Steers',
  [BAREBACK_ON_STEERS]: 'Bareback on Steers',
  [RIBBON_ROPING]: 'Ribbon Roping',
};

export const disciplinesByAbbreviation = {
  [TEAM_ROPING_HEELER]: 'HL',
  [DALLY_RIBBON_RUNNER]: 'RN',
};

export const defaultDisciplineId = BAREBACK;

export const nominatableDisciplines = [
  BAREBACK,
  BARREL_RACING,
  LADIES_BREAKAWAY_ROPING,
  BULL_RIDING,
  SADDLE_BRONC,
  STEER_WRESTLING,
  TEAM_ROPING_HEADER,
  TEAM_ROPING_HEELER,
  TIE_DOWN,
  STEER_ROPING,
  LADIES_POLE_BENDING,
  LADIES_GOAT_TYING,
];

const filterNominationDisciplines = [
  BARREL_RACING,
  LADIES_BREAKAWAY_ROPING,
  TEAM_ROPING_HEADER,
  TIE_DOWN,
  STEER_WRESTLING,
  BAREBACK,
  SADDLE_BRONC,
  BULL_RIDING,
  STEER_ROPING,
  LADIES_GOAT_TYING,
  LADIES_POLE_BENDING,
];

export const timedDisciplines = [
  TEAM_ROPING_HEADER,
  TEAM_ROPING_HEELER,
  TIE_DOWN,
  BARREL_RACING,
  STEER_WRESTLING,
  LADIES_BREAKAWAY_ROPING,
  JUNIOR_BARREL_RACE,
  JUNIOR_BREAKAWAY,
  JUNIOR_TEAM_ROPING,
  OVER_55S_TEAM_ROPING,
  GOAT_TYING,
  TIEDOWN_CALF_ROPING,
  DALLY_RIBBON_ROPER,
  DALLY_RIBBON_RUNNER,
  GIRLS_BREAKAWAY_ROPING,
  LADIES_GOAT_TYING,
  BOYS_BREAKAWAY_ROPING,
  BOYS_GOAT_TYING,
  CHUTE_DOGGING,
];

export const roughStockDisciplines = [
  BAREBACK,
  SADDLE_BRONC,
  BULL_RIDING,
  JUNIOR_STEER_RIDE,
  JUNIOR_BULL_RIDING,
  OTHER,
  LADIES_STEER_UNDECORATING,
  SIDE_POT,
  RANCH_BRONC_RIDING,
  LADIES_POLE_BENDING,
  SADDLE_BRONC_ON_STEERS,
  BAREBACK_ON_STEERS,
  RIBBON_ROPING,
];

export const barrelRacingDisciplines = [BARREL_RACING, JUNIOR_BARREL_RACE];

export const disciplineCategories = {
  [BAREBACK]: ROUGH_STOCK_TYPE,
  [SADDLE_BRONC]: ROUGH_STOCK_TYPE,
  [TEAM_ROPING_HEADER]: TIMED_DISCIPLINE_TYPE,
  [TEAM_ROPING_HEELER]: TIMED_DISCIPLINE_TYPE,
  [TIE_DOWN]: TIMED_DISCIPLINE_TYPE,
  [BARREL_RACING]: BARREL_RACING_TYPE,
  [BULL_RIDING]: ROUGH_STOCK_TYPE,
  [STEER_WRESTLING]: TIMED_DISCIPLINE_TYPE,
  [LADIES_BREAKAWAY_ROPING]: TIMED_DISCIPLINE_TYPE,
  [STEER_ROPING]: TIMED_DISCIPLINE_TYPE,
  [JUNIOR_BARREL_RACE]: BARREL_RACING_TYPE,
  [JUNIOR_STEER_RIDE]: ROUGH_STOCK_TYPE,
  [JUNIOR_BULL_RIDING]: ROUGH_STOCK_TYPE,
  [JUNIOR_BREAKAWAY]: TIMED_DISCIPLINE_TYPE,
  [JUNIOR_TEAM_ROPING]: TIMED_DISCIPLINE_TYPE,
  [OTHER]: ROUGH_STOCK_TYPE,
  [LADIES_STEER_UNDECORATING]: ROUGH_STOCK_TYPE,
  [SIDE_POT]: ROUGH_STOCK_TYPE,
  [RANCH_BRONC_RIDING]: ROUGH_STOCK_TYPE,
  [OVER_55S_TEAM_ROPING]: TIMED_DISCIPLINE_TYPE,
  [LADIES_POLE_BENDING]: ROUGH_STOCK_TYPE,
  [GOAT_TYING]: TIMED_DISCIPLINE_TYPE,
  [TIEDOWN_CALF_ROPING]: TIMED_DISCIPLINE_TYPE,
  [DALLY_RIBBON_ROPER]: TIMED_DISCIPLINE_TYPE,
  [DALLY_RIBBON_RUNNER]: TIMED_DISCIPLINE_TYPE,
  [GIRLS_BREAKAWAY_ROPING]: TIMED_DISCIPLINE_TYPE,
  [LADIES_GOAT_TYING]: TIMED_DISCIPLINE_TYPE,
  [BOYS_BREAKAWAY_ROPING]: TIMED_DISCIPLINE_TYPE,
  [BOYS_GOAT_TYING]: TIMED_DISCIPLINE_TYPE,
  [CHUTE_DOGGING]: TIMED_DISCIPLINE_TYPE,
  [SADDLE_BRONC_ON_STEERS]: ROUGH_STOCK_TYPE,
  [BAREBACK_ON_STEERS]: ROUGH_STOCK_TYPE,
  [RIBBON_ROPING]: ROUGH_STOCK_TYPE,
};

const personalHorseDisciplines = [LADIES_BREAKAWAY_ROPING, BARREL_RACING];
export const isPersonalHorseDiscipline = (disciplineId) => {
  const discId = parseInt(disciplineId, 10);
  return personalHorseDisciplines.includes(discId);
};

export const getDisciplineOptions = () => {
  return Object.keys(disciplinesByName).map((disciplineId) => {
    return {
      value: disciplineId,
      label: disciplinesByName[disciplineId],
    };
  });
};

export const getDisciplineFilterOptions = () => {
  return filterNominationDisciplines.map((disciplineId) => {
    return {
      value: isTeamDiscipline(disciplineId)
        ? getTeamDisciplineName(disciplineId)
        : disciplineId,
      label: isTeamDiscipline(disciplineId)
        ? getTeamDisciplineName(disciplineId)
        : disciplinesByName[disciplineId],
    };
  });
};

export const teamDisciplines = [
  TEAM_ROPING_HEADER,
  TEAM_ROPING_HEELER,
  DALLY_RIBBON_ROPER,
  DALLY_RIBBON_RUNNER,
];

// Allows consolidation of team roping disciplines based on which one is the primary.
const primaryTeamDisciplines = [TEAM_ROPING_HEADER, DALLY_RIBBON_ROPER];
const secondaryTeamDisciplines = [TEAM_ROPING_HEELER, DALLY_RIBBON_RUNNER];

export const isTeamDiscipline = (disciplineId) => {
  const discId = parseInt(disciplineId, 10);
  return teamDisciplines.includes(discId);
};
export const isPrimaryTeamDiscipline = (disciplineId) => {
  const discId = parseInt(disciplineId, 10);
  return primaryTeamDisciplines.includes(discId);
};
export const isSecondaryTeamDiscipline = (disciplineId) => {
  const discId = parseInt(disciplineId, 10);
  return secondaryTeamDisciplines.includes(discId);
};

export const isNominatableDiscipline = (disciplineId) => {
  const discId = parseInt(disciplineId, 10);
  return nominatableDisciplines.includes(discId);
};

export const getPartnerDisciplineId = (disciplineId) => {
  const discId = parseInt(disciplineId, 10);
  if (!teamDisciplines.includes(discId)) return null;

  const partnerDisciplineTypes = {
    [TEAM_ROPING_HEADER]: TEAM_ROPING_HEELER,
    [TEAM_ROPING_HEELER]: TEAM_ROPING_HEADER,
    [DALLY_RIBBON_ROPER]: DALLY_RIBBON_RUNNER,
    [DALLY_RIBBON_RUNNER]: DALLY_RIBBON_ROPER,
  };

  return partnerDisciplineTypes[discId];
};

export const TEAM_ROPING_NAME = 'Team Roping';
export const DALLY_RIBBON_NAME = 'Dally Ribbon Roping';

export const teamDisciplineCouple = {
  [TEAM_ROPING_NAME]: [TEAM_ROPING_HEADER, TEAM_ROPING_HEELER],
};

export const getTeamDisciplineName = (disciplineId) => {
  const discId = parseInt(disciplineId, 10);
  if (!teamDisciplines.includes(discId)) return '';

  const teamDisciplineTypes = {
    [TEAM_ROPING_HEADER]: TEAM_ROPING_NAME,
    [TEAM_ROPING_HEELER]: TEAM_ROPING_NAME,
    [DALLY_RIBBON_ROPER]: DALLY_RIBBON_NAME,
    [DALLY_RIBBON_RUNNER]: DALLY_RIBBON_NAME,
  };

  return teamDisciplineTypes[discId];
};

export const getTeamDisciplineRole = (disciplineId) => {
  const discId = parseInt(disciplineId, 10);
  if (!teamDisciplines.includes(discId)) return '';

  const teamDisciplineTypes = {
    [TEAM_ROPING_HEADER]: 'Header',
    [TEAM_ROPING_HEELER]: 'Heeler',
    [DALLY_RIBBON_ROPER]: 'Roper',
    [DALLY_RIBBON_RUNNER]: 'Runner',
  };

  return teamDisciplineTypes[discId];
};

export const DISCIPLINES_WITH_LIMITED = [
  BARREL_RACING,
  LADIES_BREAKAWAY_ROPING,
];
