import { capitalizeString } from 'helpers';

export const competitionLevel = {
  OPEN: 'open',
  YOUTH: 'youth',
  CHALLENGER: 'challenger',
  PRO: 'pro',
  DYJR: {
    value: 'dyjr',
    label: 'Junior',
  },
  DYYTH: {
    value: 'dyyth',
    label: 'Youth',
  },
  LIMITED: 'limited',
};

export const competitionLevels = [
  {
    value: competitionLevel.OPEN,
    label: capitalizeString(competitionLevel.OPEN),
  },
  {
    value: competitionLevel.YOUTH,
    label: capitalizeString(competitionLevel.YOUTH),
  },
  {
    value: competitionLevel.DYYTH.value,
    label: capitalizeString(competitionLevel.DYYTH.label),
  },
  {
    value: competitionLevel.DYJR.value,
    label: capitalizeString(competitionLevel.DYJR.label),
  },
  {
    value: competitionLevel.CHALLENGER,
    label: capitalizeString(competitionLevel.CHALLENGER),
  },
  {
    value: competitionLevel.PRO,
    label: capitalizeString(competitionLevel.PRO),
  },
  // TODO: Feature on-hold
  // {
  //   value: competitionLevel.LIMITED,
  //   label: capitalizeString(competitionLevel.LIMITED),
  // },
];
