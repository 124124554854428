import api from 'api';
import { getFromState, getAccessToken, logError } from 'helpers';

export const PAYMENT_ACTION_SUCCESS = 'PAYMENT_ACTION_SUCCESS';
export const PAYMENT_ACTION_CLEAR = 'PAYMENT_ACTION_CLEAR';
export const PAYMENT_ASYNC_START = 'PAYMENT_ASYNC_START';
export const PAYMENT_ASYNC_FAIL = 'PAYMENT_ASYNC_FAIL';
export const PAYMENT_ASYNC_SUCCESS = 'PAYMENT_ASYNC_SUCCESS';
export const PAYMENT_ACTION_INIT_AMOUNT = 'PAYMENT_ACTION_INIT_AMOUNT';

export const PAYMENT_ACTION_COUPON_START = 'PAYMENT_ACTION_COUPON_START';
export const PAYMENT_ACTION_COUPON_SUCCESS = 'PAYMENT_ACTION_COUPON_SUCCESS';
export const PAYMENT_ACTION_COUPON_FAIL = 'PAYMENT_ACTION_COUPON_FAIL';
export const PAYMENT_ACTION_COUPON_CLEAR = 'PAYMENT_ACTION_COUPON_CLEAR';

function couponAsyncStart() {
  return {
    type: PAYMENT_ACTION_COUPON_START,
  };
}

function couponActionSuccess(data) {
  return {
    type: PAYMENT_ACTION_COUPON_SUCCESS,
    data,
  };
}

function couponAsyncFail(error) {
  return {
    type: PAYMENT_ACTION_COUPON_FAIL,
    error,
  };
}

function couponActionClear() {
  return {
    type: PAYMENT_ACTION_COUPON_CLEAR,
  };
}

function paymentActionSuccess(data) {
  return {
    type: PAYMENT_ACTION_SUCCESS,
    data,
  };
}

export function paymentActionClear(data) {
  return {
    type: PAYMENT_ACTION_CLEAR,
    data,
  };
}

function paymentAsyncStart() {
  return {
    type: PAYMENT_ASYNC_START,
  };
}

function paymentAsyncFail(error) {
  return {
    type: PAYMENT_ASYNC_FAIL,
    error,
  };
}

function paymentAsyncSuccess() {
  return {
    type: PAYMENT_ASYNC_SUCCESS,
  };
}

function initPaymentAmount(data) {
  return {
    type: PAYMENT_ACTION_INIT_AMOUNT,
    data,
  };
}

export function validateCoupon(values) {
  return async (dispatch, getState) => {
    dispatch(couponAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getAccessToken(authPayload);

      const response = await api({
        path: 'coupon/validate',
        method: 'POST',
        body: values,
        accessToken,
      });

      dispatch(couponActionSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      const err = error.message;

      dispatch(couponAsyncFail(err));
      return err;
    }
  };
}

export function stripeRequestTokenStart() {
  return async (dispatch) => {
    dispatch(paymentAsyncStart());
  };
}

export function stripeRequestTokenFail(error) {
  return async (dispatch) => {
    dispatch(paymentAsyncFail(error));
  };
}

export function createPayment(values) {
  return async (dispatch) => {
    try {
      const { amount, nomination, giftCardAccountBalances } = values;
      const retn = await dispatch(
        initPaymentAmount({ amount, nomination, giftCardAccountBalances }),
      );
      return retn;
    } catch (error) {
      logError(error);
      const err = 'An error occurred while trying to create payment';
      dispatch(paymentAsyncFail(err));
      return err;
    }
  };
}

export function submitNominationPayment(values) {
  return async (dispatch, getState) => {
    dispatch(paymentAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getAccessToken(authPayload);

      const response = await api({
        path: 'payment/pay-for-nominations',
        method: 'POST',
        body: values,
        accessToken,
      });

      if (!response.err && !response.error) {
        dispatch(paymentActionSuccess(response));
      }
      return response;
    } catch (error) {
      logError(error);
      const err = 'An error occurred while trying to create payment';
      dispatch(paymentAsyncFail(err));
      return { error };
    }
  };
}

export function listSavedCards(EventUID) {
  return async (dispatch, getState) => {
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getAccessToken(authPayload);

      const response = await api({
        path: 'payment/cards',
        method: 'POST',
        accessToken,
        body: {
          EventUID,
        },
      });

      return response;
    } catch (error) {
      logError(error);
      const err = 'An error occurred while trying to list saved cards';

      dispatch(paymentAsyncFail(err));
      return null;
    }
  };
}

export function deleteSavedCard(id) {
  return async (dispatch, getState) => {
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getAccessToken(authPayload);

      const response = await api({
        path: 'payment/cards',
        method: 'DELETE',
        accessToken,
        body: { cardId: id },
      });

      return response;
    } catch (error) {
      logError(error);
      const err = 'An error occurred while trying to delete a saved card';

      dispatch(paymentAsyncFail(err));
      return null;
    }
  };
}

export function calculateCharge(values) {
  return async (dispatch, getState) => {
    try {
      dispatch(paymentAsyncStart());
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getAccessToken(authPayload);

      const response = await api({
        path: 'event/calculate-charges',
        method: 'POST',
        body: values,
        accessToken,
      });

      dispatch(paymentAsyncSuccess());
      return response;
    } catch (error) {
      logError(error);
      let err = 'An error occurred while trying to calculate charges';
      if (error.message) {
        err = error.message;
      }
      dispatch(paymentAsyncFail(err));
      return { error: { message: err } };
    }
  };
}

export function calculateEntryCharge(values) {
  return async (dispatch, getState) => {
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getAccessToken(authPayload);

      const response = await api({
        path: 'event/entries/calculate-charges',
        method: 'POST',
        body: values,
        accessToken,
      });

      return response;
    } catch (error) {
      logError(error);
      let err = 'An error occurred while trying to calculate charges';
      if (error.message) {
        err = error.message;
      }
      return { error: { message: err } };
    }
  };
}

export function clearPayment(payment) {
  return (dispatch) => {
    dispatch(paymentActionClear(payment));
  };
}

export function clearCoupon() {
  return (dispatch) => {
    dispatch(couponActionClear());
  };
}
